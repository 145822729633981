import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth'
import user from './module/user'
import survey from './module/survey'
import job from './module/job'
import menu from './module/menu'
import dashboard from './module/dashboard'
import banner from './module/banner'
import gallery from './module/gallery'
import pengumuman from './module/pengumuman'
import posting from './module/posting'
import likes from './module/likes'
import commentar from './module/commentar'
import news from './module/news'
import member from './module/member'
import product from './module/product'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  modules: {
    auth,
    user,
    survey,
    job,
    dashboard,
    menu,
    banner,
    pengumuman,
    news,
    gallery,
    posting,
    likes,
    commentar,
    member,
    product
  },
  mutations
})