import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getSurvey(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('survey', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        addSurvey(_, data){
            return new Promise((resolve,reject) => {
                axios.post('survey/create', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updateSurvey(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('survey/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteSurvey(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('survey/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
    },
    getters: {

    }

}