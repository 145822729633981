import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        getStatus(_) {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard').then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getLegal(_) {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/countLegal').then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getRegional(_) {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/countRegional').then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        }
    },
    getters: {

    }
}