import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getBanner(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('banner', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        addBanner(_, data){
            return new Promise((resolve,reject) => {
                axios.post('banner/create', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updateBanner(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('banner/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteBanner(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('banner/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
    },
    getters: {

    }

}