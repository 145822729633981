 import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - menu
const Dashboard = () => import('@/views/menu/dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Alumni = () => import('@/views/pages/Alumni')
const Index = () => import('@/views/pages/Index')
const User = () => import('@/views/menu/User')
const Survey = () => import('@/views/menu/Survey')
const Job = () => import('@/views/menu/Job')
const Menu = () => import('@/views/menu/Menu')
const Banner = () => import('@/views/menu/Banner')
const Gallery = () => import('@/views/menu/Gallery')
const Pengumuman = () => import('@/views/menu/Pengumuman')
const LoginUser = () => import('@/views/pages/LoginUser')
const Chat = () => import('@/views/member/Chat')
const BasaEta = () => import('@/views/pages/BasaEta')
const News = () => import('@/views/menu/News')
const Posting = () => import('@/views/menu/Posting')
const Komentar = () => import('@/views/menu/Komentar')
const Likes = () => import('@/views/menu/Likes')
const Members = () => import('@/views/menu/Member')
const Products = () => import('@/views/menu/Product')

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'User',
          name: 'Kelola User',
          component: User
        },
        {
          path: 'Survey',
          name: 'Kelola Survey',
          component: Survey
        },
        {
          path: 'Job',
          name: 'Kelola Job',
          component: Job
        },
        {
          path: 'Banner',
          name: 'Kelola Banner',
          component: Banner
        },
        {
          path: 'Gallery',
          name: 'Kelola Gallery',
          component: Gallery
        },
        {
          path: 'Pengumuman',
          name: 'Kelola Pengumuman',
          component: Pengumuman
        },
        {
          path: 'News',
          name: 'Kelola News',
          component: News
        },
        {
          path: 'Posting',
          name: 'Kelola Posting',
          component: Posting
        },
        {
          path: 'Komentar',
          name: 'Kelola Komentar',
          component: Komentar
        },
        {
          path: 'Likes',
          name: 'Kelola Likes',
          component: Likes
        },
        {
          path: 'Menu',
          name: 'Kelola Menu',
          component: Menu
        },
        
        {
          path: 'Member',
          name: 'Kelola Struktur',
          component: Members
        },
        {
          path: 'Product',
          name: 'Kelola Produk',
          component: Products
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'login-user',
          name: 'Login User',
          component: LoginUser
        },
        {
          path: 'survey-alumni',
          name: 'Survey Alumni',
          component: Alumni
        },
        {
          path: 'index',
          name: 'Index',
          component: Index
        },
        {
          path: 'basa-eta',
          name: 'Basa Eta',
          component: BasaEta
        },
      ]
    }
  ]
}

