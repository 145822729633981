import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getCommentar(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('commentar', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getUserCommentar(_, id){
            return new Promise((resolve,reject) => {
                axios.get('commentar/user/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
        addCommentar(_, data){
            return new Promise((resolve,reject) => {
                axios.post('commentar/create', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updateCommentar(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('commentar/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deleteCommentar(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('commentar/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
    },
    getters: {

    }

}