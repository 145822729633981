import axios from 'axios'; 


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getPengumuman(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('pengumuman', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        getPengumumanLanding(_,_params){
            return new Promise((resolve, reject) => {
                axios.get('get-pengumuman', {params : _params}).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        addPengumuman(_, data){
            return new Promise((resolve,reject) => {
                axios.post('pengumuman/create', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },

        updatePengumuman(_, {id,data}){
            return new Promise((resolve,reject) => {
                axios.put('pengumuman/update/'+ id, data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        deletePengumuman(_, id){
            return new Promise((resolve,reject) => {
                axios.delete('pengumuman/delete/'+ id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },
        
    },
    getters: {

    }

}